import { createStore } from 'vuex';

import moment from 'moment';

import i18n from '@/i18n';

import User from '@/store/modules/User';
import CompanyProjectStatus from '@/store/modules/CompanyProjectStatus';
import ChecklistOptionsPanel from '@/store/modules/ChecklistOptionsPanel';
import TaskOptionsPanel from '@/store/modules/TaskOptionsPanel';
import CompanyProjectTemplates from '@/store/modules/CompanyProjectTemplates';
import Projects from '@/store/modules/Projects';
import DirectChats from '@/store/modules/DirectChats';
import Notifications from '@/store/modules/Notifications';
import Files from '@/store/modules/Files';
import Folders from '@/store/modules/Folders';
import Chats from '@/store/modules/Chats';
import Messages from '@/store/modules/Messages';
import Companies from '@/store/modules/Companies';
import Integrations from '@/store/modules/Integrations';
import InviteMembers from '@/store/modules/InviteMembers';
import Labels from '@/store/modules/Labels';
import App from '@/store/modules/App';
import Other from '@/store/modules/Other';
import Checklists from '@/store/modules/Checklists';
import Comments from '@/store/modules/Comments';
import Referrals from '@/store/modules/Referrals';
import RegistrationTypes from '@/store/modules/RegistrationTypes';
import RegistrationTypesGroups from '@/store/modules/RegistrationTypesGroups';
import ProjectCalculations from '@/store/modules/ProjectCalculations';
import Registrations from '@/store/modules/Registrations';
import Filters from '@/store/modules/Filters';
import ImportFiles from '@/store/modules/ImportFiles';
import ProjectsImports from '@/store/modules/ProjectsImports';
import MaterialsImports from '@/store/modules/MaterialsImports';

import PlanningResourcePlanItems from '@/store/modules/PlanningResourcePlanItems';
import PlanningResourceGroups from '@/store/modules/PlanningResourceGroups';
import PlanningResources from '@/store/modules/PlanningResources';

import FormEditor from '@/store/modules/FormEditor.js';
import Forms from '@/store/modules/Forms.js';

import PdfImports from '@/store/modules/PdfImports.js';

import ModalsManager from '@/store/modules/ModalsManager';
import { createI18nPlugin } from './plugins/i18n';

import { sortChats } from '@/helpers/chats.js';
import { sortChecklists, filterChecklists } from '@/helpers/checklists.js';

import * as presets from '@/components/Planning/presets.js';
import { normalizeBusinessTime } from '@/helpers/businessTime/dto';

import { TARIFF_PLAN } from '@/constants.js';

const { fullTimePreset } = presets;

const debug = process.env.NODE_ENV !== "production";
const i18nPlugin = createI18nPlugin(i18n, moment);

const extractChecklistOption = ({ id, name, access, isDefault, chatId }) => {
  const option = {
    id,
    placeholder: access ? `🔒 ${name}` : name,
  };

  if (isDefault) {
    Object.assign(option, { isDefault, chatId });
  }

  return option;
};

const getters = {
  useNewLayout: (_state, getters) => {
    const company = getters['Companies/meCompany'];
    return !company?.id || company?.planId === TARIFF_PLAN.WOUB_2;
  },

  canUseForms: (_state, getters) => !getters['useNewLayout'] && getters['User/me'].canUseForms,

  formsEnabled: (_state, getters) => {
    const meCompany = getters['Companies/meCompany'];
    return Boolean(meCompany?.formsEnabled);
  },

  canViewProjectManagementInfo: (_state, getters) => {
    const permissions = getters['User/permissions'];
    return permissions.project?.canViewManagementInfo ?? false;
  },

  filterChecklistsByPermissions: (_state, getters) => checklists =>
    getters.canViewProjectManagementInfo ? checklists : filterChecklists(checklists),

  getOrderedProjectChecklists: (_state, getters) => projectId => {
    const projectChecklists = getters['Checklists/getterProjectChecklists'](projectId);
    const checklists = getters.filterChecklistsByPermissions(projectChecklists);
    return sortChecklists(checklists);
  },

  getOrderedProjectChats: (_state, getters) => projectId => {
    const getChatsByProjectId = getters['Chats/getChatsByProjectId'];
    return sortChats(getChatsByProjectId(projectId));
  },

  getOrderedProjectChecklistsFromChats: (_state, getters) => projectId => {
    const projectChats = getters.getOrderedProjectChats(projectId);

    const chatChecklists = projectChats.flatMap(chat => {
      const chatChecklists = getters['Checklists/getterChatChecklists'](chat.id);
      const checklists = getters.filterChecklistsByPermissions(chatChecklists);
      return sortChecklists(checklists);
    });

    return chatChecklists;
  },

  getAllProjectChecklists: (_state, getters) => projectId => {
    const projectChecklists = getters.getOrderedProjectChecklists(projectId);
    const chatChecklists = getters.getOrderedProjectChecklistsFromChats(projectId);
    return [...projectChecklists, ...chatChecklists];
  },

  getProjectBusinessTime: (_state, getters) => projectId => {
    const project = getters['Projects/getProjectById'](projectId);
    const { workingHours } = project ?? {};

    return workingHours ? normalizeBusinessTime(workingHours) : fullTimePreset;
  },

  getChecklistOptions:
    (_state, getters) =>
    ({
      projectId,
      allProjectChecklists,
      checklist,
      isTask,
      chatId,
      isCreateModal,
      fallbackProjectName,
    }) => {
      const { useNewLayout } = getters;
      const permissions = getters['User/permissions'];
      const optionsAndGroups = [];

      const getChatChecklistOptions = id =>
        getters['Checklists/getterChatChecklists'](id)
          .filter(e => !e.access || permissions.project.canViewManagementInfo)
          .sort((a, b) => a.position - b.position)
          .map(extractChecklistOption);

      if (chatId) {
        const list = getChatChecklistOptions(chatId);
        optionsAndGroups.push(...list);
      } else if (projectId) {
        const projectChecklists = useNewLayout
          ? []
          : getters['Checklists/getterProjectChecklists'](projectId);

        const projectChecklistsOptions = projectChecklists
          .filter(c => !c.favourite)
          .sort((a, b) => a.position - b.position)
          .map(extractChecklistOption);

        const projectFavouriteChecklistsOptions = projectChecklists
          .filter(c => c.favourite)
          .sort((a, b) => a.position - b.position)
          .map(extractChecklistOption);

        const { name: projectName = fallbackProjectName ?? '' } =
          getters['Projects/getProjectById'](projectId) ?? {};

        // favourite project checklists
        if (projectFavouriteChecklistsOptions.length) {
          const placeholder = i18n.global.t('modals.checklistItem.favourites');
          optionsAndGroups.push({
            id: 'favourites',
            list: projectFavouriteChecklistsOptions,
            placeholder,
          });
        }

        // other project checklists
        if (
          (projectChecklistsOptions.length && allProjectChecklists) ||
          (projectChecklistsOptions.length && projectFavouriteChecklistsOptions.length)
        ) {
          const placeholder = `${i18n.global.t('modals.checklistItem.project')}: ${projectName}`;
          optionsAndGroups.push({
            id: 'project',
            list: projectChecklistsOptions,
            placeholder,
          });
        } else if (projectChecklistsOptions.length === 0 && allProjectChecklists && !useNewLayout) {
          const placeholder = `${i18n.global.t('modals.checklistItem.project')}: ${projectName}`;

          const optionAsNewChecklist = extractChecklistOption({
            id: -1,
            name: 'Checklist',
            access: false,
          });

          optionsAndGroups.push({
            id: 'project',
            list: [optionAsNewChecklist],
            placeholder,
          });
        } else if (projectChecklistsOptions.length) {
          optionsAndGroups.push(...projectChecklistsOptions);
        }

        // chat checklists
        const projectChats = getters['Chats/getChatsByProjectId'](projectId);

        if (projectChats.length && (allProjectChecklists || useNewLayout)) {
          projectChats.forEach(projectChat => {
            const list = getChatChecklistOptions(projectChat.id);
            const groupPrefix = i18n.global.t('modals.checklistItem.chat');

            if (list.length) {
              optionsAndGroups.push({
                id: `chat-${projectChat.id}`,
                placeholder: `${groupPrefix}: ${projectChat.name}`,
                list,
              });
            } else if (useNewLayout) {
              optionsAndGroups.push({
                id: `chat-${projectChat.id}`,
                placeholder: `${groupPrefix}: ${projectChat.name}`,
                list: [
                  extractChecklistOption({
                    id: `chat-${projectChat.id}`,
                    name: 'Checklist',
                    access: false,
                    chatId: projectChat.id,
                    isDefault: true,
                  }),
                ],
              });
            }
          });
        }
      } else {
        const optionAsPreviousChecklist = extractChecklistOption({
          id: checklist?.id,
          name: i18n.global.t('modals.checklistItem.noProjectSelected'),
          access: checklist?.access,
        });

        optionsAndGroups.push(optionAsPreviousChecklist);
      }

      if (!optionsAndGroups.length) {
        const optionAsNewChecklist = extractChecklistOption({
          id: -1,
          name: 'Checklist',
          access: false,
        });

        optionsAndGroups.push(optionAsNewChecklist);
      }

      const defaultChecklists = () => {
        if (projectId && !isTask && !isCreateModal) {
          return [];
        } else if (projectId) {
          return [
            {
              id: undefined,
              name: i18n.global.t('modals.checklistItem.selectChecklist'),
              access: false,
              position: 0,
            },
          ];
        } else {
          return [];
        }
      };

      const defaultOptions = defaultChecklists().map(extractChecklistOption);
      return [...defaultOptions, ...optionsAndGroups];
    },

  getUsersAndResources:
    (state, getters) =>
    ({ userIds, resourceIds }) => {
      const { resourcesByIds } = state.PlanningResources;
      const meCompanyMembersMap = getters['Companies/meCompanyMembersMap'];

      return {
        users: userIds.map(userId => meCompanyMembersMap[userId]).filter(value => !!value),
        resources: resourceIds
          .map(resourceId => resourcesByIds[resourceId])
          .filter(value => !!value),
      };
    },
};

const actions = {
  setChecklistItemLabel: async (
    { dispatch },
    { label, oldLabel, checklistId, checklistItemId }
  ) => {
    if (label && !label.id) {
      label = await dispatch('Labels/addLabel', label);
    }

    if (oldLabel) {
      await dispatch('Checklists/deleteChecklistItemLabel', {
        checklistId,
        checklistItemId,
        labelId: oldLabel.id,
      });
    }

    if (label) {
      await dispatch('Checklists/addChecklistItemLabel', {
        checklistId,
        checklistItemId,
        labelId: label.id,
      });
    }
  },
};

const Store = createStore({
  getters,
  actions,

  modules: {
    Other,
    App,
    User,
    Projects,
    DirectChats,
    CompanyProjectStatus,
    CompanyProjectTemplates,
    Notifications,
    Files,
    Folders,
    Chats,
    ChecklistOptionsPanel,
    TaskOptionsPanel,
    Messages,
    Companies,
    Integrations,
    InviteMembers,
    Labels,
    Checklists,
    Comments,
    Referrals,
    RegistrationTypes,
    RegistrationTypesGroups,
    ProjectCalculations,
    Registrations,
    Filters,
    ImportFiles,
    ProjectsImports,
    MaterialsImports,

    PlanningResourcePlanItems,
    PlanningResourceGroups,
    PlanningResources,

    FormEditor,
    Forms,

    PdfImports,

    ModalsManager,
  },

  plugins: [i18nPlugin],

  strict: debug,
});

export default Store;
