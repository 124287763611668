import Provider from '@provider'
import {
  ProjectModel,
  LabelModel,
  ChecklistModel,
  CommentModel,
  ChatModel,
  UserModel,
  MediaFileModel,
  FolderModel,
  PublicDescriptionHistoryModel,
  ProjectContactModel,
  IntegrationMappingModel,
} from '@/models'
import { isObject } from 'lodash'
import moment from 'moment'
import { convertZonedDateToBackendDate } from '@/helpers/dates/index.js';
import helpers from '@/helpers';

export default {
  getProjectPublicDescriptionHistory: ({ projectId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/public_description_history`)
        .then(response =>
          resolve(response.data.map(e => new PublicDescriptionHistoryModel(e)))
        )
        .catch(reject)
    })
  },

  getProjectUsedContacts: ({ search, cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/used_contacts`, {
          params: {
            search,
          },
          cancelToken,
        })
        .then(response => {
          const onlyUnique = (value, index, self) => {
            if (!value) return false
            return self.indexOf(value) === index
          }

          resolve(
            response.data.map(e => {
              return {
                contactPerson: e.contact_person,
                contactPersonEmail: e.contact_person_email,
                contactPersonPhone: e.contact_person_phone,
                location: e.location,
                projectId: e.id,
              }
            })
          )
          return

          resolve({
            persons: response.data
              .map(e => e.contact_person)
              .filter(onlyUnique),

            personDescriptions: response.data
              .map(e => e.contact_person_description)
              .filter(onlyUnique),

            emails: response.data
              .map(e => e.contact_person_email)
              .filter(onlyUnique),

            phones: response.data
              .map(e => e.contact_person_phone)
              .filter(onlyUnique),
          })
        })
        .catch(reject)
    })
  },

  exportProjectHours: ({
    fileName = 'table.pdf',
    projectId,
    users = false,
    hours = false,
    dateFrom,
    dateTill,
  }) => {
    const exportT = (users ? 1 : 0) + (hours ? 2 : 0)

    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/export_hours`, {
          responseType: 'arraybuffer',
          mode: 'no-cors',
          cache: 'no-cache',
          withCredentials: false,
          headers: { 'Access-Control-Allow-Origin': '*' },
          params: {
            export_type: exportT === 3 ? undefined : exportT,
            date_from: dateFrom,
            date_till: dateTill,
            d: Date.now(),
          },
        })
        .then(response => {
          let blob = new Blob([response.data], {
            /*type: 'application/pdf'*/
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()

          resolve()
        })
        .catch(reject)
    })
  },

  markMessagesAsRead: projectId => {
    return Provider.api.post(`/projects/markmessagesread/${projectId}`)
  },

  markProject: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/projects/${projectId}/mark`)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  unmarkProject: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/projects/${projectId}/unmark`)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  getProjects: (form = {}) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/projects', form)
        .then(response => resolve(response.data.map(e => new ProjectModel(e))))
        .catch(reject)
    })
  },

  getArchivedProjects: (form = {}) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/projects/archived', form)
        .then(response => resolve(response.data.map(e => new ProjectModel(e))))
        .catch(reject)
    })
  },

  getProjectChecklists: ({ projectId, withChatChecklist }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(
          `/projects/${projectId}/checklists`,
          {
            params: {
              with_chat_checklist: withChatChecklist ? Number(withChatChecklist) : undefined,
            },
          }
        )
        .then(response =>
          resolve(response.data.map(e => new ChecklistModel(e)))
        )
        .catch(reject)
    })
  },

  getProjectComments: ({ projectId, offset, limit }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/comments`, {
          params: { offset, limit },
        })
        .then(response => resolve(response.data.map(e => new CommentModel(e))))
        .catch(reject)
    })
  },

  getProject: ({ projectId, cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}`, { cancelToken })
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  addProject: form => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/projects', form)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  duplicateProject: (id, options) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/projects/${id}/clone`, options)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  addProjectMember: ({ projectId, userId }) => {
    return Provider.api
      .post(`/projects/${projectId}/members`, { user_id: userId });
  },

  inviteExternalUser: ({ projectId, user }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/projects/${projectId}/invitemembers`, user)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  getProjectMembers: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/members`)
        .then(response => resolve(response.data.map(e => new UserModel(e))))
        .catch(reject)
    })
  },

  getProjectChats: ({ projectId, countShowUsers }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/chats`, {
          params: {
            count_show_users_in_chat: countShowUsers,
          },
        })
        .then(response => resolve(response.data.map(e => new ChatModel(e))))
        .catch(reject)
    })
  },

  archiveProject: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/projects/${projectId}/archive`)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  dearchiveProject: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/projects/${projectId}/restore`)
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  getProjectFiles: ({ projectId, access, limit, withFolders }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/projects/${projectId}/files`, {
          params: {
            access,
            limit,
            with_folders: withFolders ? 1 : undefined,
          },
        })
        .then(response => {
          resolve({
            files: response.data
              .filter(e => e.filename)
              .map(e => new MediaFileModel(e)),
            folders: response.data
              .filter(e => !e.filename)
              .map(e => new FolderModel(e)),
          })
        })
        .catch(reject)
    })
  },

  deleteProject: projectId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/projects/${projectId}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  deleteProjectMember: ({ projectId, userId }) => {
    return Provider.api
      .delete(`/projects/${projectId}/members`, {
        data: {
          user_id: userId,
        },
      });
  },

  updateProject: ({
    projectId,
    name,
    description,
    publicDescription,
    contactPerson,
    contactPersonDescription,
    contactPersonPhone,
    contactPersonEmail,
    statusId,
    position,
    location,
    number,
    calculationsNotes,
    startAt,
    endAt,
    notifyAt,
    completedAt,
    hoursBudget,
    workingHours,
  }) => {
    const start_at = convertZonedDateToBackendDate(startAt);
    const end_at = convertZonedDateToBackendDate(endAt);
    const notify_at = convertZonedDateToBackendDate(notifyAt);
    const completed_at = convertZonedDateToBackendDate(completedAt);

    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/projects/${projectId}`, {
          name,
          description,
          location,
          position,
          number,
          start_at,
          end_at,
          notify_at,
          completed_at,
          public_description: publicDescription,
          contact_person: contactPerson,
          contact_person_description: contactPersonDescription,
          contact_person_phone: contactPersonPhone,
          contact_person_email: contactPersonEmail,
          calculations_notes: calculationsNotes,
          status_id: statusId,
          hours_budget: hoursBudget,
          work_hours: workingHours,
        })
        .then(response => resolve(new ProjectModel(response.data)))
        .catch(reject)
    })
  },

  addProjectLabel: ({ projectId, labelId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/projects/${projectId}/labels`, {
          label_id: labelId,
        })
        .then(response => resolve(response.data.map(e => new LabelModel(e))))
        .catch(reject)
    })
  },

  deleteProjectLabel: ({ labelId, projectId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/projects/${projectId}/labels`, {
          params: {
            label_id: labelId,
          },
        })
        .then(response => resolve(response.data.map(e => new LabelModel(e))))
        .catch(reject)
    })
  },

  getProjectContacts: ({ projectId }) => {
    return Provider.api
      .get(`/projects/${projectId}/contacts`)
      .then(response =>
        response.data?.data?.map(item => new ProjectContactModel(item))
      );
  },

  addProjectContact: ({ projectId, name, email, phone, address }) => {
    return Provider.api
      .post(`/projects/${projectId}/contacts`, {
        name,
        email,
        phone,
        address
      })
      .then(response => new ProjectContactModel(response.data?.data));
  },

  getProjectContact: ({ projectId, contactId }) => {
    return Provider.api
      .get(`/projects/${projectId}/contacts/${contactId}`)
      .then(response => new ProjectContactModel(response.data?.data));
  },

  updateProjectContact: ({
    projectId,
    contactId,
    name,
    email,
    phone,
    address,
    access,
  }) => {
    return Provider.api
      .put(`/projects/${projectId}/contacts/${contactId}`, {
        name,
        email,
        phone,
        address,
        access,
      })
      .then(response => new ProjectContactModel(response.data?.data));
  },

  deleteProjectContact: ({ projectId, contactId }) => {
    return Provider.api.delete(`/projects/${projectId}/contacts/${contactId}`);
  },

  exportProjectRegistrations: ({
    projectId,
    registrationHours,
    registrationMaterial,
    registrationNotes,
    withPrices,
    startAt,
    endAt
  }) => {
    return Provider.api
      .get(
        `/projects/${projectId}/export_registrations`,
        {
          params: {
            registration_hours: Number(registrationHours),
            registration_material: Number(registrationMaterial),
            registration_notes: Number(registrationNotes),
            with_prices: Number(withPrices),
            start_at: startAt ? moment(startAt).format('YYYY-MM-DD') : null,
            end_at: endAt ? moment(endAt).format('YYYY-MM-DD') : null,
          },
          responseType: 'blob',
        }
      );
  },

  exportProjectCalculations: ({
    projectId,
    withPrices,
    option,
  }) => {
    return Provider.api
      .get(
        `/projects/${projectId}/export_calculations`,
        {
          params: {
            invoice_option: Number(option),
            with_prices: Number(withPrices),
          },
          responseType: 'blob',
        }
      );
  },

  exportFinancialProjectCalculations: ({
    projectId,
  }) => {
    return Provider.api
        .get(
            `/projects/${projectId}/export_management`,
            {
              params: {},
              responseType: 'blob',
            }
        );
  },

  getProjectIntegrationDetails: ({ projectId }) => {
    return Provider.api
      .get(`projects/${projectId}/integration`)
      .then(
        response =>
          response.data?.data?.map?.(mapping => new IntegrationMappingModel(mapping)) ?? null
      );
  },

  exportPlanning: ({ projectId, checklistIds, view, orientation, onlyPlannedItems }) => {
    const data = {
      checklist_ids: checklistIds,
      view,
      orientation,
      only_planned_items: onlyPlannedItems,
    };

    return Provider.api.post(`projects/${projectId}/export_planning`, data, { responseType: 'blob' })
  },
}
