import _ from 'lodash'
import Model from '@/models/Model'
import UserRules from './UserRules'
import ChecklistModel from './Checklist'

const STATUS_CHAT = {
  ONLINE: 'online',
  OFFLINE: 'offline',
}

class UserModel extends Model {
  constructor(data) {
    super(data)
    this.canCreateChat = data.can_create_chat
    this.canCreateProject = data.can_create_project
    this.canCreateProjectStatus = data.can_create_company_project_status
    this.canDeleteMember = data.can_delete_member || false
    this.canViewProjectRegistrationsReport = Boolean(
      data.can_view_registration_reports
    )
    this.canUseProjectRegistration = Boolean(data.can_use_registration)
    this.canUseForms = Boolean(data.can_use_forms);

    for (let key in data) {
      this[key] = data[key]
    }

    this.firstName = _.get(data, 'first_name', '')
    this.middleName = _.get(data, 'middle_name', '')
    this.lastName = _.get(data, 'last_name', '')

    this.fullName = _.get(
      data,
      'fullName',
      [this.firstName, this.middleName, this.lastName]
        .filter((name) => Boolean(name))
        .join(' ')
    )

    this.language = data.language

    this.roleId = data.role_id
    this.isGuest = data.is_guest
    this.companyId = data.company_id
    this.avatar = data.avatar
    this.chatStatus = data.chat_status === STATUS_CHAT.ONLINE

    this.isAdmin = this.roleId === 2;
    this.isMember = this.roleId == 4

    this.updatedAt = this.fixData(data.updated_at_utc);
    // this.lastSeenAt = this.fixData(data.last_seen_at);

    this.hasCompany = !!data.company_id
    this.tourDesktop = !data.tour_desktop

    this.isInvited = !!data.token
    this.isExternal = data.is_external

    this.employeeNumber = data.employee_number

    this.permissions = new UserRules(data.permissions)

    if (data.count_checklistitems)
      this.countTasks = Number(data.count_checklistitems)

    if (data.checklist) this.personalChecklistId = data?.checklist?.id

    this.oauthProvider = data.oauth_provider
    this.oauthEmail = data.oauth_email
  }
}

export default UserModel
