import Model from '@/models/Model'
import LabelModel from '@/models/Label'
import ProjectContactModel from '@/models/ProjectContact'
import { constructWorkingHours, getDefaultWorkingHours } from '@/helpers/working-hours.js';

class ProjectModel extends Model {
  constructor(data) {
    super(data)

    this.canInviteToProject = data.can_invite_to_project
    this.canMove = data.can_move
    this.canViewinfo = data.can_view_info
    this.canRestore = data.can_restore
    this.canLeave = data.can_leave

    this.canViewManagementInfo = data.can_view_management_info
    this.canViewRegistrationManagement = data.can_view_registration_management

    this.canCreateChecklist = Boolean(data.can_create_checklist)

    this.id = data.id
    this.name = data.name
    this.number = data.number
    this.description = data.description
    this.publicDescription = data.public_description
      ? String(data.public_description).replace(/(?:\r\n|\r|\n)/g, '<br>')
      : ''

    this.companyId = data.company_id
    this.companyProjectStatusId = data.company_project_status_id
    this.firstProjectChatId = data.first_project_chat_id || null

    this.parentProjectId = data.parent_project_id
    this.parentProjectBy = data.parent_project_by

    this.contactPerson = data.contact_person
    this.contactPersonDescription = data.contact_person_description
    this.contactPersonPhone = data.contact_person_phone
    this.contactPersonEmail = data.contact_person_email

    this.defaultContact = data.default_contact
      ? new ProjectContactModel(data.default_contact)
      : null;

    this.checklistChecked = data.checklist_checked || 0
    this.checklistTotal = data.checklist_total || 0
    this.marked = Boolean(data.marked)

    this.countNewMessages = data.count_new_messages

    this.lastMessageAt = data.last_message_at_utc
      ? this.fixData(data.last_message_at_utc)
      : null

    this.updatedAt = this.fixData(data.updated_at_utc)
    this.createdAt = this.fixData(data.created_at_utc)
    this.deletedAt = this.fixData(data.deleted_at_utc)
    this.archivedAt = this.fixData(data.archived_at_utc)

    // MOMENT FORMAT DATES
    this.lastMessageAtM = data.last_message_at_utc
      ? this.toMomentDate(data.last_message_at_utc)
      : null

    this.updatedAtM = this.toMomentDate(data.updated_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
    this.deletedAtM = this.toMomentDate(data.deleted_at_utc)
    this.archivedAtM = data.archived_at_utc ? this.toMomentDate(data.archived_at_utc) : null

    // TIME OUT
    this.startAt = data.start_at_utc ? this.toMomentDate(data.start_at_utc) : null
    this.endAt = data.end_at_utc ? this.toMomentDate(data.end_at_utc) : null
    this.completedAt = data.completed_at_utc
      ? this.toMomentDate(data.completed_at_utc)
      : null

    this.notifyAt = data.notify_at_utc ? this.toMomentDate(data.notify_at_utc) : null

    // END TIME OUT

    this.position = Number(data.position)

    this.location = data.location

    this.calculationsNotes = data.calculations_notes

    this.isShared = !!data.parent_project_id
    this.isIndefinite = !data.company_project_status_id
    this.isArchived = !!data.archived_at

    this.hoursBudget = Number(data.hours_budget) || null;
    this.hoursRegisteredCount = Number(data.hours_registered_count) || null;
    this.hoursPlannedCount = Number(data.hours_planned_count) || null;
    this.hoursRemaining =
      this.hoursBudget - this.hoursPlannedCount - this.hoursRegisteredCount || null;

    this.labels = data.labels ? data.labels.map(e => new LabelModel(e)) : []

    this.protectedFilesCount = data.protected_files_count
    this.filesCount = data.files_count
    this.users = data.users ?? [];

    this.workingHours = data.work_hours
      ? constructWorkingHours(data.work_hours)
      : getDefaultWorkingHours();

    this.totalRevenue = data.total_revenue || null;
    this.totalCosts = data.total_costs || null;
    this.profit = this.totalRevenue - this.totalCosts || null;
    this.profitPercents = this.totalRevenue
      ? (100 / this.totalRevenue) * this.profit || null
      : null;

    this.totalInvoiced = data.total_invoiced || null;
    this.totalNotInvoiced = this.totalRevenue - this.totalInvoiced || null;
  }

  get duedate() {
    return {
      startAt: this.startAt,
      endAt: this.endAt,
    }
  }
}

export default ProjectModel
