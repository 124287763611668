<template>
  <div class="ui email-input">
    <ui-scrollbar class="list selected" :class="{ error }">
      <template v-for="(item, index) in items" :key="index">
        <slot name="item" :item="item" :index="index">
          <div class="item">
            <div class="body">
              <div class="name">{{ item }}</div>
              <button class="cross" type="button" @click="removeEmail(index)"></button>
            </div>
          </div>
        </slot>
      </template>

      <ui-input
        ref="input"
        :model-value="email"
        :class="{ error }"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :readonly="readonly"
        @update:model-value="handleInputEmail"
        @keyup.enter="validateAndAddEmail"
        @keyup.space="validateAndAddEmail"
        @keyup.,="validateAndAddEmail"
      />
    </ui-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'UiEmailInput',
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    email: {
      type: String,
      default: '',
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    error: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  emits: ['update:email', 'update:error', 'add-item', 'remove-item', 'update:items'],

  expose: ['isValidEnteredEmail', 'validateAndAddEmail', 'focus'],

  computed: {
    emailModel: {
      get() {
        return this.email;
      },

      set(email) {
        this.$emit('update:email', email?.trim?.());
      },
    },

    formattedEmail() {
      return this.email.replace(/[, ]/g, '');
    },

    /**
     * @public
     */
    isValidEnteredEmail() {
      const { formattedEmail } = this;
      return this.$utils.$validation.isValidEmail(formattedEmail);
    },
  },

  methods: {
    handleInputEmail(email) {
      this.emailModel = email;
    },

    /**
     * Validate and add an entered email
     * @public
     */
    validateAndAddEmail(event) {
      this.$emit('update:error', false);

      if (!this.isValidEnteredEmail) {
        this.$emit('update:error', true);
      } else {
        this.addEmail();
      }
    },

    addEmail() {
      const { formattedEmail } = this;
      this.$emit('add-item', formattedEmail);
      this.$emit('update:items', [...this.items, formattedEmail]); // to using the `v-model` directive
      this.emailModel = '';
    },

    removeEmail(index) {
      this.$emit('remove-item', index);
      this.$emit('update:items', this.items.slice().splice(index, 1)); // to using the `v-model` directive
    },

    /**
     * Focus the input
     * @public
     */
    focus() {
      this.$refs.input?.focus?.();
    },
  }
}
</script>

<style lang="sass" src="./styles.sass"></style>
