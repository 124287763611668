import Model from '@/models/Model'
import UserModel from '@/models/User'
import ProjectModel from '@/models/Project'
import { RegistrationTypeModel } from '@/models/RegistrationType'

const REGISTRATION_TYPES = {
  MATERIAL: 'M',
  HOURS_PROJECT: 'H',
  HOURS_GENERALLY: 'G',
}

class RegistrationModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.name = data.name
    this.amount = Number(data.amount)
    this.startAt = this.toMomentTime(data.start_at)
    this.endAt = this.toMomentTime(data.end_at)

    this.date = this.fixData(data.date)
    this.dateStr = data.date
    this.dateMoment = this.toMomentDate(data.date)

    this.userId = data.user ? data.user.id : null
    this.user = data.user ? new UserModel(data.user) : null
    this.project = data.project ? new ProjectModel(data.project) : null

    this.comment = data.comment

    this.registrationTypeId = data.registration_type_id
    this.registrationType = data.registration_type
      ? new RegistrationTypeModel(data.registration_type)
      : null
  }
}

export default RegistrationModel
