import { cloneDeep } from 'lodash'

import Store from '@/store'
import ChatsProvider from '@provider/chats'
import UsersProvider from '@provider/user'
import * as TYPES from '@/store/types'

// initial state
const state = {
  chats: [],
}

// getters
const getters = {
  getChatById: state => id => {
    return state.chats.find(e => e.id == id)
  },

  getChatsByProjectId: state => projectId => {
    return state.chats
      .filter(e => e.projectId == projectId)
      .sort((a, b) => a.id - b.id)
  },

  teamChats: state => state.chats.filter(e => !e.isProject),
}

// actions
const actions = {
  getChats({ commit }, { chatIds }) {
    return ChatsProvider.getChats({ chatIds })
      .then(chats => {
        commit(TYPES.CHATS.INIT_CHATS, chats);
      });
  },

  getUsersChat({ commit }, search) {
    return new Promise((resolve, reject) => {
      UsersProvider.getUsersChat(search)
        .then(chats => {
          commit(TYPES.CHATS.INIT_CHATS, chats)
          // commit(TYPES.DIRECT_CHATS.SWITCH_LOADED, false)
          resolve(chats)
        })
        .catch(reject)
    })
  },
  
  markMessagesRead({ commit }, { chatId }) {
    return ChatsProvider.markMessagesRead({ chatId })
      .then(() => {
        commit(TYPES.CHATS.UPDATE_CHAT_MESSAGE_COUNTER, { chatId, counter: 0 });
      });
  },

  exportChatToPDF(_, form) {
    return ChatsProvider.exportChatToPDF(form)
  },

  downloadAllChatFiles(_, form) {
    return ChatsProvider.downloadAllChatFiles(form)
  },

  addChat({ dispatch }, form) {
    return ChatsProvider.addChat(form)
      .then(chat => {
        return dispatch('getChat', chat.id);
      });
  },

  leaveChat({ state, commit }, chatId) {
    return ChatsProvider.leaveChat(chatId)
      .then(() => {
        commit(TYPES.CHATS.DELETE_CHAT, Number(chatId));
      });
  },

  deleteChat({ state, commit }, chatId) {
    return ChatsProvider.deleteChat(chatId)
      .then(() => {
        commit(TYPES.CHATS.DELETE_CHAT, Number(chatId));
      });
  },

  getChatChecklists({}, { chatId }) {
    return new Promise((resolve, reject) => {
      ChatsProvider.getChatChecklists({ chatId })
        .then(checklists => {
          checklists.forEach((checklist) => {
            Store.commit('Checklists/INIT_CHECKLIST', checklist);
          });
          resolve(checklists)
        })
        .catch(reject)
    })
  },

  getChat({ commit }, chatId) {
    return new Promise((resolve, reject) => {
      ChatsProvider.getChat(chatId)
        .then(chat => {
          commit('INIT_CHAT', chat)
          resolve(chat)
        })
        .catch(reject)
    })
  },

  getChatFiles({}, { chatId, limit }) {
    return new Promise((resolve, reject) => {
      ChatsProvider.getChatFiles({ chatId, limit })
        .then(list => {
          Store.commit('Files/INIT_FILES', list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  sendFilesToChats({ commit }, { fileIds, chatIds }) {
    return ChatsProvider.sendFilesToChats({ fileIds, chatIds }).then(mediaFiles => {
      commit(TYPES.FILES.MOD.INIT_FILES, mediaFiles, { root: true });
    });
  },

  getChatMembers({ state, commit }, chatId) {
    return ChatsProvider.getChatMembers(chatId)
      .then((members) => {
        commit(TYPES.CHATS.SET_CHAT_MEMBERS, { chatId: Number(chatId), members });
        return members;
      });
  },

  addChatMember({ commit }, { chatId, user }) {
    return ChatsProvider.addChatMember({ chatId, userId: user.id })
      .then(() => {
        const member = cloneDeep(user);
        // if a user can add a member, he also can delete it
        member.canDeleteMember = true;

        commit(TYPES.CHATS.INIT_CHAT_MEMBER, {
          chatId: Number(chatId),
          member,
        });
      });
  },

  deleteChatMember({ commit }, { chatId, userId }) {
    return ChatsProvider.deleteChatMember({ chatId, userId })
      .then(() => {
        commit(TYPES.CHATS.DELETE_CHAT_MEMBER, {
          chatId: Number(chatId),
          userId
        });
      });
  },

  chatInviteExternalUser({ state, commit }, { chatId, projectId, user }) {
    return ChatsProvider.chatInviteExternalUser({ chatId, projectId, user })
      .then((invite) => {
        if (!invite.usedAt) {
          // if a user can invite an user, he also can delete it
          invite.canDelete = true;
          commit(TYPES.INVITEMEMBERS.MOD.INIT_INVITES, invite, { root: true });
          commit(TYPES.CHATS.UPDATE_CHAT_IS_SHARED, {
            chatId: Number(chatId),
            isShared: true,
          });
        }
      });
  },

  updateChat({ state, commit }, { chatId, fields }) {
    return new Promise((resolve, reject) => {
      ChatsProvider.updateChat({ chatId, fields })
        .then(() => {
          commit(TYPES.CHATS.UPDATE_CHAT_NAME, { chatId: Number(chatId), name: fields.name });
          resolve(chat)
        })
        .catch(reject)
    })
  },

  muteChat({ state, commit }, { chatId, mute }) {
    return new Promise((resolve, reject) => {
      ChatsProvider.muteChat({ chatId, mute })
        .then(() => {
          commit(TYPES.CHATS.UPDATE_CHAT_MUTE, { chatId: Number(chatId), mute});
          resolve(chat)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.CHATS.UPDATE_CHAT_MESSAGE_COUNTER](state, { chatId, counter }) {
    let chat = state.chats.find(e => e.id == chatId)
    if (chat) {
      chat.countNewMessages = counter
    }
  },

  [TYPES.CHATS.DELETE_CHAT](state, chatId) {
    state.chats = state.chats.filter(e => e.id != chatId)
  },

  [TYPES.CHATS.UPDATE_LAST_MESSAGE](state, lastMessage) {
    if (!lastMessage) return null

    let chat = state.chats.find(e => e.id == lastMessage.chatId)
    if (chat) {
      chat.lastMessage = lastMessage
      chat.lastMessageAt = lastMessage.createdAt
      chat.lastMessageUserFirstName = lastMessage.user
        ? lastMessage.user.firstName
        : null
    }
  },

  [TYPES.CHATS.UPDATE_CHAT_NAME](state, { chatId, name }) {
    const chat = state.chats.find(chat => chat.id === chatId);
    if (chat) chat.name = name;
  },

  [TYPES.CHATS.UPDATE_CHAT_MUTE](state, { chatId, mute }) {
    const chat = state.chats.find(chat => chat.id === chatId);
    if (chat) chat.muted = mute;
  },

  [TYPES.CHATS.UPDATE_CHAT_IS_SHARED](state, { chatId, isShared }) {
    const chat = state.chats.find(chat => chat.id === chatId);
    if (chat) chat.isShared = isShared;
  },

  [TYPES.CHATS.SET_CHAT_MEMBERS](state, { chatId, members }) {
    const chat = state.chats.find(chat => chat.id === chatId);
    if (chat) chat.users = members;
  },

  [TYPES.CHATS.INIT_CHAT_MEMBER](state, { chatId, member }) {
    const chat = state.chats.find(chat => chat.id === chatId);

    if (chat) {
      const index = chat.users.findIndex((user) => user.id === member.id);

      if (index !== -1) {
        chat.users.splice(index, 1, member);
      } else {
        chat.users.push(member);
      }
    }
  },

  [TYPES.CHATS.DELETE_CHAT_MEMBER](state, { chatId, userId }) {
    const chat = state.chats.find(chat => chat.id === chatId);

    if (chat) {
      chat.users = chat.users.filter((user) => user.id !== userId);
    }
  },

  INIT_CHATS(state, data) {
    let chats = Array.isArray(data) ? data : [data],
      ids = chats.map(e => e.id)

    state.chats = [
      ...state.chats.filter(e => !ids.includes(e.id)),
      ...chats,
    ]
  },

  INIT_CHAT(state, chat) {
    state.chats = [...state.chats.filter(e => e.id !== chat.id), chat]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
