import Model from '@/models/Model'

const TEAM_CHAT_STATUS = {
    NULL: 'offline',
    OFFLINE: 'offline',
    ONLINE: 'online',
    BUSY: 'busy',
  },
  TYPES = {
    PROJECT: 'project',
    GROUP: 'group',
    PRIVATE: 'private',
    COMPANY: 'company',
  }

class TeamChatModel extends Model {
  constructor(data = {}) {
    super(data)

    this.id = data.chat_id
    this.status = TEAM_CHAT_STATUS[String(data.chat_status).toUpperCase()]

    this.name = data.name
    this.countNewMessages = data.count_new_messages
    this.userId = data.user_id
    this.toTop = data.to_top || false
    this.userCount = data.user_count || 0

    this.isPrivate = data.chat_type === TYPES.PRIVATE
    this.isProject = data.chat_type === TYPES.PROJECT
    this.isGroup = data.chat_type === TYPES.GROUP
    this.isGroup =
      data.chat_type === TYPES.GROUP || data.chat_type === TYPES.COMPANY

    this.lastMessageTime = this.fixData(data.last_message_time)

    this.canCreateChecklist = Boolean(data.can_create_checklist)
  }
}

export { TeamChatModel, TEAM_CHAT_STATUS }
