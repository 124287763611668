import { defineAsyncComponent } from 'vue';

export const installProjectListModals = app => {
  app.component('ModalProjectListChooseColumns', defineAsyncComponent(() => import('./ChooseColumns')));
  app.component('ModalProjectListEditProjectStatus', defineAsyncComponent(() => import('./EditProjectStatus')));
  app.component('ModalProjectListArchive', defineAsyncComponent(() => import('./Archive')));
  app.component('ModalProjectListFilters', defineAsyncComponent(() => import('./Filters')));
  app.component('ModalProjectListContactPerson', defineAsyncComponent(() => import('./ContactPerson')));
  app.component('ModalProjectListContactAddress', defineAsyncComponent(() => import('./ContactAddress')));
  app.component('ModalProjectListEditField', defineAsyncComponent(() => import('./EditField')));
  app.component('ModalProjectListSaveFilter', defineAsyncComponent(() => import('./SaveFilter')));
};
