import * as TYPES from '@/store/types';

// initial state
const state = {
  checkedItems: [],
  showPanel: false,
  showPanelPermanently: false,
  isShowChecklistOptions: false,
};

// getters
const getters = {
  itemsCount: state => state.checkedItems.length,
};

// actions
const actions = {
  setFlag: ({ commit }, value) => {
    commit(TYPES.CHECKLIST_OPTIONS.SET_MODAL_FLAG, value);
  },
  showCheckListHeaderOptions: ({ commit }, value) => {
    commit(TYPES.CHECKLIST_OPTIONS.SHOW_CHECKLIST_OPTIONS, value);
  },
  clear: ({ commit }) => {
    commit(TYPES.CHECKLIST_OPTIONS.UNCHECK_ALL);
  },
  togglePanel: ({ commit, state }, value) => {
    if (!state.showPanelPermanently && !value) commit(TYPES.CHECKLIST_OPTIONS.TOGGLE_PANEL, value);
  },
  toggleItems: ({ commit }, { checked, items, fromPanel }) => {
    commit(TYPES.CHECKLIST_OPTIONS.TOGGLE_ALL_ITEMS, { checked, items, fromPanel });
  },
  updateCheckedItems: ({ commit }, { item, checked }) => {
    commit(TYPES.CHECKLIST_OPTIONS.UPDATE_ITEM, { item, checked });
  },
};
// mutations
const mutations = {
  [TYPES.CHECKLIST_OPTIONS.SET_MODAL_FLAG](state, value) {
    state.showPanelPermanently = value;
  },
  [TYPES.CHECKLIST_OPTIONS.SHOW_CHECKLIST_OPTIONS](state, value) {
    state.isShowChecklistOptions = value;
  },
  [TYPES.CHECKLIST_OPTIONS.UNCHECK_ALL](state) {
    state.checkedItems = [];
  },
  [TYPES.CHECKLIST_OPTIONS.TOGGLE_PANEL](state, value) {
    state.showPanel = value;
  },
  [TYPES.CHECKLIST_OPTIONS.TOGGLE_ALL_ITEMS](state, { checked, items, fromPanel }) {
    if (checked) {
      state.checkedItems = [...items];
    } else {
      state.checkedItems = [];
      state.showPanel = fromPanel ? true : false;
    }
  },
  [TYPES.CHECKLIST_OPTIONS.UPDATE_ITEM](state, { item, checked }) {
    if (checked) {
      state.showPanel = true;
    }
    const { checklistId, id, userIds, resourceIds } = item;
    if (checked) {
      state.checkedItems.push({ checklistId, id, userIds, resourceIds });
    } else {
      state.checkedItems = state.checkedItems.filter(i => i.id !== id);
      if (!state.checkedItems.length) {
        state.showPanel = false;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
