import Provider from "@provider";
import {SearchModel} from "@/models";

export default {
  ping: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/ping")
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  search: ({ search, cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/search/", { params: { search }, cancelToken })
        .then(response => resolve(response.data.projects.map(e => new SearchModel(e))))
        .catch(error => reject(error));
    });
  },

  getOnboardingQuestions: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/onboarding/initquestions")
        .then((response) => resolve({
          amountPeople: response.data.amount_people,
          specialism: response.data.specialism,
          usageReasons: response.data.usage_reasons
        }))
        .catch((error) => reject(error));
    });
	}
}