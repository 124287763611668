import { defineAsyncComponent } from 'vue';
import { installProjectListModals } from './ProjectListModals';
import { installChecklistModals } from '@/views/Home/List/Checklists/modals/index.js';

export const installModalComponents = app => {
  installProjectListModals(app);
  installChecklistModals(app);

  app.component('ModalSelectUsers', defineAsyncComponent(() => import('./SelectUsers')));
  app.component('ModalSelectUsersAndResources', defineAsyncComponent(() => import('./SelectUsersAndResources/index.vue')));
  app.component('ModalPrepareDownloadFile', defineAsyncComponent(() => import('./PrepareDownloadFile')));
  app.component('ModalChecklistExport', defineAsyncComponent(() => import('./Checklist/Export')));
  app.component('ModalProjectRegistrationsExport', defineAsyncComponent(() => import('./ProjectRegistrationsExport')));
  app.component('ModalProjectCalculationsExport', defineAsyncComponent(() => import('./ProjectCalculationsExport')));
  app.component('ModalApproveArchiveAllProjects', defineAsyncComponent(() => import('./ApproveArchiveAllProjects.vue')));
  app.component('ModalHourCheck', defineAsyncComponent(() => import('./HourCheck/Index.vue')));
  app.component('ModalApproveDeleteProjectStatus', defineAsyncComponent(() =>
    import('./ApproveDeleteProjectStatus.vue')
  ));
  app.component('ModalProjectOptions', defineAsyncComponent(() => import('./projectOptions/Index.vue')));
  app.component('ModalExportTimesheet', defineAsyncComponent(() => import('./ExportTimesheet/Index.vue')));
  app.component('ModalCreateChecklistItem', defineAsyncComponent(() => import('./ChecklistItem/Create.vue')));
  app.component('ModalUpdateChecklistItem', defineAsyncComponent(() => import('./ChecklistItem/Update.vue')));
  app.component('ModalChecklistItemsFilters', defineAsyncComponent(() => import('./ChecklistItemsFilters/Index.vue')));
  app.component('ModalChecklistsImportPdf', defineAsyncComponent(() => import('./ChecklistsImportPdf/Index.vue')));
  app.component('ModalApproveRemove', defineAsyncComponent(() => import('./ApproveRemove/Index.vue')));
  app.component('ModalCropImage', defineAsyncComponent(() => import('./CropImage/Index.vue')));
  app.component('ModalNotifications', defineAsyncComponent(() => import('./Notifications')));
  app.component('ModalDeleteMemberFrom', defineAsyncComponent(() => import('./DeleteMemberFrom')));
  app.component('ModalDuedate', defineAsyncComponent(() => import('./Duedate')));
  app.component('ModalDuedatePick', defineAsyncComponent(() => import('./Duedate/Pick')));
  app.component('ModalSelectColor', defineAsyncComponent(() => import('./SelectColor')));
  app.component('ModalSelectLabels', defineAsyncComponent(() => import('./SelectLabels')));
  app.component('ModalSelectChats', defineAsyncComponent(() => import('./SelectChats')));
  app.component('ModalProjectPublicDescriptionHistory', defineAsyncComponent(() =>
    import('./ProjectPublicDescriptionHistory')
  ));

  /** Planning */
  app.component('ModalPlanningExport', defineAsyncComponent(() => import('./PlanningExport/Index.vue')));
  app.component('ModalPlanningCreateItem', defineAsyncComponent(() => import('./PlanningItem/Create')));
  app.component('ModalPlanningChangeItem', defineAsyncComponent(() => import('./PlanningItem/Change')));
  app.component('ModalPlanningChangeGroup', defineAsyncComponent(() => import('./PlanningGroup/Change')));
  app.component('ModalPlanningCreateGroup', defineAsyncComponent(() => import('./PlanningGroup/Create')));

  app.component('ModalEditEmployee', defineAsyncComponent(() => import('./EditEmployee/Index.vue')));
  app.component('ModalCreateNewProject', defineAsyncComponent(() => import('./CreateNewProject/Index.vue')));
  app.component('ModalOnboardingInviteToCompany', defineAsyncComponent(() =>
    import('./OnboardingInviteToCompany/Index.vue')
  ));
  app.component('ModalRemoveMemberFromProject', defineAsyncComponent(() =>
    import('./RemoveMemberFromProject/Index.vue')
  ));
  app.component('ModalPurposeOfUse', defineAsyncComponent(() => import('./PurposeOfUse/Index.vue')));
  app.component('ModalCreateChecklist', defineAsyncComponent(() => import('./CreateChecklist/Index.vue')));
  app.component('ModalCreateFolder', defineAsyncComponent(() => import('./CreateFolder/Index.vue')));
  app.component('ModalUploadFilesCommentChecklistItem', defineAsyncComponent(() =>
    import('./UploadFile/modules/CommentChecklistItem.vue')
  ));
  app.component('ModalUploadFiles', defineAsyncComponent(() => import('./UploadFile/modules/Default.vue')));
  app.component('ModalContextMenu', defineAsyncComponent(() => import('./ContextMenu/Index.vue')));

  app.component('ModalMoveMediaToFolder', defineAsyncComponent(() => import('./MoveMediaToFolder/Index.vue')));
  app.component('ModalChooseOneDriveFolder', defineAsyncComponent(() => import('./ChooseOneDriveFolder/Index.vue')));
  app.component('ModalMediaShareToChats', defineAsyncComponent(() => import('./MediaShareToChats/Index.vue')));
  app.component('ModalRenameMedia', defineAsyncComponent(() => import('./RenameMedia/Index.vue')));

  app.component('ModalInviteMemberToChat', defineAsyncComponent(() => import('./InviteMemberToChat/Index.vue')));

  app.component('ModalUploadFoldersProgress', defineAsyncComponent(() => import('./UploadFoldersProgress/Index.vue')));
  app.component('ModalDownloadFoldersProgress', defineAsyncComponent(() =>
    import('./DownloadFoldersProgress/Index.vue')
  ));

  app.component('ModalBulkCreateProjects', defineAsyncComponent(() => import('./BulkCreateProjects/Index.vue')));
  app.component('ModalBulkMessageSender', defineAsyncComponent(() => import('./BulkMessageSender/Index.vue')));
  app.component('ModalLabels', defineAsyncComponent(() => import('./Labels/Index.vue')));
  app.component('ModalLabelsPalette', defineAsyncComponent(() => import('./Labels/Palette.vue')));
  app.component('ModalEditLabel', defineAsyncComponent(() => import('./EditLabel/Index.vue')));
  app.component('ModalNewChat', defineAsyncComponent(() => import('./NewChat/Index.vue')));
  app.component('ModalNewPrivateChat', defineAsyncComponent(() => import('./NewPrivateChat/Index.vue')));

  app.component('ModalNewProjectPhase', defineAsyncComponent(() => import('./ProjectPhase/New.vue')));
  app.component('ModalUpdateProjectPhase', defineAsyncComponent(() => import('./ProjectPhase/Update.vue')));

  app.component('ModalJoinSyncAccount', defineAsyncComponent(() => import('./JoinSyncAccount/JoinSyncAccount.vue')));
  app.component('ModalUploadAvatar', defineAsyncComponent(() => import('./UploadAvatar/Index.vue')));
  app.component('ModalViewMedia', defineAsyncComponent(() => import('./ViewMedia/Index.vue')));

  app.component('ModalProjectPlanningExport', defineAsyncComponent(() => import('./ProjectPlanningExport/Index.vue')));
  app.component('ModalWorkingTime', defineAsyncComponent(() => import('./WorkingTime/Index.vue')));
  app.component('ModalImportCUF', defineAsyncComponent(() => import('./ImportCUF/Index.vue')));
  app.component('ModalImportCUFOptions', defineAsyncComponent(() => import('./ImportCUFOptions/Index.vue')));

  app.component('ModalCreateRegistration', defineAsyncComponent(() => import('./Registration/Create.vue')));
  app.component('ModalUpdateRegistration', defineAsyncComponent(() => import('./Registration/Update.vue')));

  app.component('ModalInviteMemberToCompany', defineAsyncComponent(() => import('@/modals/InviteToCompany/Member.vue')));
  app.component('ModalInviteAdminToCompany', defineAsyncComponent(() => import('@/modals/InviteToCompany/Admin.vue')));
  app.component('ModalInviteGuestToCompany', defineAsyncComponent(() => import('@/modals/InviteToCompany/Guest.vue')));

  app.component('ModalInviteMemberToProject', defineAsyncComponent(() =>
    import('@/modals/InviteMemberToProject/Index.vue')
  ));

  app.component('ModalInviteToChat', defineAsyncComponent(() => import('@/modals/InviteToChat/Index.vue')));

  app.component('ModalOnboardingQuestions', defineAsyncComponent(() => import('@/modals/OnboardingQuestions/Index.vue')));
  app.component('ModalPasswordChange', defineAsyncComponent(() => import('@/modals/PasswordChange/Index.vue')));

  app.component('ModalRemoveRegistrationHours', defineAsyncComponent(() => import('@/modals/RemoveRegistration/Hours')));
  app.component('ModalRemoveRegistrationMaterial', defineAsyncComponent(() =>
    import('@/modals/RemoveRegistration/Material')
  ));

  app.component('ModalCalendar', defineAsyncComponent(() => import('@/modals/Calendar')));

  app.component('ModalApproveDeleteMessage', defineAsyncComponent(() => import('@/modals/ApproveDeleteMessage.vue')));
  app.component('ModalApproveLeaveProject', defineAsyncComponent(() => import('@/modals/ApproveLeaveProject.vue')));
  app.component('ModalApproveLeaveChat', defineAsyncComponent(() => import('@/modals/ApproveLeaveChat.vue')));
  app.component('ModalApproveDeleteChat', defineAsyncComponent(() => import('@/modals/ApproveDeleteChat.vue')));
  app.component('ModalApproveChangeEmployee', defineAsyncComponent(() => import('@/modals/ApproveChangeEmployee.vue')));
  app.component('ModalApproveDeleteEmployee', defineAsyncComponent(() => import('@/modals/ApproveDeleteEmployee.vue')));
  app.component('ModalDeleteProject', defineAsyncComponent(() => import('@/modals/DeleteProject/Index.vue')));
  app.component('ModalDuplicateProject', defineAsyncComponent(() => import('@/modals/DuplicateProject/Index.vue')));
  app.component('ModalAddFileLink', defineAsyncComponent(() => import('@/modals/AddFileLink/Index.vue')));
  app.component('ModalUploadFileMenu', defineAsyncComponent(() => import('./UploadFileMenu/Index.vue')));
  app.component('ModalExportLogs', defineAsyncComponent(() => import('./ExportLogs/Index.vue')));
  app.component('ModalConfirmation', defineAsyncComponent(() => import('@/modals/Confirmation/Index.vue')));
  app.component('ModalCountrySelect', defineAsyncComponent(() => import('@/modals/CountrySelect/Index.vue')));

  app.component('ModalAdminCodes', defineAsyncComponent(() => import('@/modals/AdminCodes/Index.vue')));
  app.component('ModalImportFileDetails', defineAsyncComponent(() => import('@/modals/ImportFileDetails/Index.vue')));
  app.component('ModalProjectsImportFileDetails', defineAsyncComponent(() =>
    import('@/modals/ProjectsImportFileDetails/Index.vue')
  ));
  app.component('ModalMaterialsImportFileDetails', defineAsyncComponent(() =>
    import('@/modals/MaterialsImportFileDetails/Index.vue')
  ));
  app.component('ModalInput', defineAsyncComponent(() => import('@/modals/Input/Index.vue')));
  app.component('ModalIntegrationCreateInvoice', defineAsyncComponent(() =>
    import('@/modals/IntegrationCreateInvoice/Index.vue')
  ));

  app.component('ModalCreateRegistrationTypesGroup', defineAsyncComponent(() =>
    import('@/modals/CreateRegistrationTypesGroup/Index.vue')
  ));
  app.component('ModalRegistrationTypeGroups', defineAsyncComponent(() =>
    import('@/modals/RegistrationTypeGroups/Index.vue')
  ));

  app.component('ModalProjectCalculationConstituents', defineAsyncComponent(() =>
    import('@/modals/ProjectCalculationConstituents/Index.vue')
  ));

  app.component('ModalKanbanFilter', defineAsyncComponent(() => import('@/modals/KanbanFilter/Index.vue')));
  app.component('ModalFilterSave', defineAsyncComponent(() => import('@/modals/FilterSave/Index.vue')));

  app.component('ModalFormClone', defineAsyncComponent(() => import('@/modals/FormClone/Index.vue')));
  app.component('ModalFormResponse', defineAsyncComponent(() => import('@/modals/FormResponse/Index.vue')));

  app.component('ModalWithdrawTips', defineAsyncComponent(() => import('@/modals/WithdrawTips/Index.vue')));
  app.component('ModalPreviewReferralEmail', defineAsyncComponent(() => import('@/modals/PreviewReferralEmail/Index.vue')));
};
