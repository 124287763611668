class TYPE_S {
  constructor(obj) {
    for (const key in obj) {
      this[key] = obj[key]
    }
  }

  get MOD() {
    let res = {}
    for (let key in this) {
      if (key == 'MOD') continue
      res[key] = this.MODULE + this[key]
    }

    return res
  }
}

export const APP = new TYPE_S({
  MODULE: 'App/',
  CHANGE_NETWORK: 'CHANGE_NETWORK',
  UPDATE_SHOW_CHAT_SIDEBAR: 'UPDATE_SHOW_CHAT_SIDEBAR',
  UPDATE_SHOW_MEDIA_STYLE: 'UPDATE_SHOW_MEDIA_STYLE',
  UPDATE_SORT_MEDIA_BY: 'UPDATE_SORT_MEDIA_BY',
  UPDATE_VIEW_LIST_PROJECTS: 'UPDATE_VIEW_LIST_PROJECTS',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
})

export const PLANNING_RESOURCE_GROUPS = new TYPE_S({
  MODULE: 'PlanningResourceGroups/',
  INIT_GROUPS: 'INIT_GROUPS',
  DELETE_GROUPS: 'DELETE_GROUPS',
  TOGGLE_VIEW_GROUP: 'TOGGLE_VIEW_GROUP',
})

export const PLANNING_RESOURCES = new TYPE_S({
  MODULE: 'PlanningResources/',
  INIT_RESOURCES: 'INIT_RESOURCES',
  DELETE_RESOURCES: 'DELETE_RESOURCES',
  SET_LOADING_RESOURCES: 'SET_LOADING_RESOURCES',
})

export const PLANNING_RESOURCE_PLAN_ITEMS = new TYPE_S({
  MODULE: 'PlanningResourcePlanItems/',
  INIT_RESOURCE_PLAN_ITEMS: 'INIT_RESOURCE_PLAN_ITEMS',
  DELETE_RESOURCE_PLAN_ITEMS: 'DELETE_RESOURCE_PLAN_ITEMS',
  UPDATE_TIME_RESOURCE_PLAN_ITEM: 'UPDATE_TIME_RESOURCE_PLAN_ITEM',
  SET_RESOURCE_PLAN_ITEM: 'SET_RESOURCE_PLAN_ITEM',
  SET_CANCELLATION: 'SET_CANCELLATION',
  SET_LOADING: 'SET_LOADING',
  SET_LAST_UPDATE_REQUEST: 'SET_LAST_UPDATE_REQUEST',
  UPDATE_PLAN_ITEM_RESOURCES: 'UPDATE_PLAN_ITEM_RESOURCES',
  SET_SEARCH_PLAN_ITEM_ID: 'SET_SEARCH_PLAN_ITEM_ID',
  SET_PLAN_ITEM_IS_SEARCH: 'SET_PLAN_ITEM_IS_SEARCH',
})

export const DIRECT_CHATS = new TYPE_S({
  MODULE: 'DirectChats/',
  INIT_CHATS: 'INIT_CHATS',
  SWITCH_LOADED: 'SWITCH_LOADED',
  SWITCH_CHAT_STATUS: 'SWITCH_CHAT_STATUS',
  INIT_CHAT_STATUSES: 'INIT_CHAT_STATUSES',
})

export const REGISTRATION_TYPES = new TYPE_S({
  MODULE: 'RegistrationTypes/',
  INIT_REGISTRATION_TYPES: 'INIT_REGISTRATION_TYPES',
  DELETE_REGISTRATION_TYPE: 'DELETE_REGISTRATION_TYPE',
})

export const REGISTRATION_TYPES_GROUPS = new TYPE_S({
  MODULE: 'RegistrationTypesGroups/',
  INIT_REGISTRATION_TYPES_GROUPS: 'INIT_REGISTRATION_TYPES_GROUPS',
  DELETE_REGISTRATION_TYPES_GROUP: 'DELETE_REGISTRATION_TYPES_GROUP',
  SET_MATERIALS_FILTER_GROUP_ID: 'SET_MATERIALS_FILTER_GROUP_ID'
})

export const PROJECT_CALCULATIONS = new TYPE_S({
  MODULE: 'ProjectCalculations/',
  INIT_PROJECT_CALCULATIONS: 'INIT_PROJECT_CALCULATIONS',
  DELETE_PROJECT_CALCULATION: 'DELETE_PROJECT_CALCULATION',
})

export const MESSAGES = new TYPE_S({
  MODULE: 'Messages/',
  INIT_MESSAGES: 'INIT_MESSAGES',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  TOGGLE_MARK_DELETING: 'TOGGLE_MARK_DELETING',
  INIT_FAIL_MESSAGE: 'INIT_FAIL_MESSAGE',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  DELETE_FAIL_MESSAGE: 'DELETE_FAIL_MESSAGE',

  INIT_DRAFT_MESSAGE: 'INIT_DRAFT_MESSAGE',
  DELETE_DRAFT_MESSAGE: 'DELETE_DRAFT_MESSAGE',
})

export const PROJECTS = new TYPE_S({
  MODULE: 'Projects/',
  INIT_PROJECTS: 'INIT_PROJECTS',
  UPDATE_POSITION: 'UPDATE_POSITION',
  INIT_PROJECT_LABELS: 'INIT_PROJECT_LABELS',
  REMOVE_PROJECT_LABELS: 'REMOVE_PROJECT_LABELS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  UPDATE_PROJECT_MESSAGE_COUNTER: 'UPDATE_PROJECT_MESSAGE_COUNTER',
  SWITCH_LOADED: 'SWITCH_LOADED',
  UPDATE_LAST_MESSAGE_AT: 'UPDATE_LAST_MESSAGE_AT',
  UPDATE_PROJECT_FILE_COUNTER: 'UPDATE_PROJECT_FILE_COUNTER',
  INIT_PUBLIC_DESCRIPTION_HISTORY: 'INIT_PUBLIC_DESCRIPTION_HISTORY',
  SET_PROJECT_MEMBERS: 'SET_PROJECT_MEMBERS',
  INIT_PROJECT_MEMBER: 'INIT_PROJECT_MEMBER',
  DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER',
  SET_IS_HOURS_UPDATING: 'SET_IS_HOURS_UPDATING',
  SET_PROJECT_CONTACTS: 'SET_PROJECT_CONTACTS',
  INIT_PROJECT_CONTACT: 'INIT_PROJECT_CONTACT',
  UPDATE_PROJECT_CONTACT: 'UPDATE_PROJECT_CONTACT',
  SET_PROJECT_DEFAULT_CONTACT: 'SET_PROJECT_DEFAULT_CONTACT',
  REMOVE_PROJECT_CONTACT: 'REMOVE_PROJECT_CONTACT',
  SET_ADDING_CONTACT: 'SET_ADDING_CONTACT',
  ADD_UPDATING_CONTACT: 'ADD_UPDATING_CONTACT',
  REMOVE_UPDATING_CONTACT: 'REMOVE_UPDATING_CONTACT',
  INIT_PROJECT_INTEGRATION: 'INIT_PROJECT_INTEGRATION',
})

export const FILTERS = new TYPE_S({
  MODULE: 'Filters/',
  INIT_FILTERS: 'INIT_FILTERS',
  UPDATE_KANBAN_FILTER: 'UPDATE_KANBAN_FILTER',
  UPDATE_DASHBOARD_LOCAL_FILTER: 'UPDATE_DASHBOARD_LOCAL_FILTER',
  UPDATE_TASKS_LOCAL_FILTER: 'UPDATE_TASKS_LOCAL_FILTER',
  PATCH_TASKS_LOCAL_FILTER: 'PATCH_TASKS_LOCAL_FILTER',
  UPDATE_POSITION_DASHBOARD_FILTER: 'UPDATE_POSITION_DASHBOARD_FILTER',
  UPDATE_POSITION_TASKS_FILTER: 'UPDATE_POSITION_TASKS_FILTER',
  DELETE_FILTER: 'DELETE_FILTER',
})

export const CHATS = new TYPE_S({
  MODULE: 'Chats/',
  INIT_CHATS: 'INIT_CHATS',
  DELETE_CHAT: 'DELETE_CHAT',
  UPDATE_CHAT_MESSAGE_COUNTER: 'UPDATE_CHAT_MESSAGE_COUNTER',
  UPDATE_LAST_MESSAGE: 'UPDATE_LAST_MESSAGE',
  UPDATE_CHAT_NAME: 'UPDATE_CHAT_NAME',
  UPDATE_CHAT_MUTE: 'UPDATE_CHAT_MUTE',
  UPDATE_CHAT_IS_SHARED: 'UPDATE_CHAT_IS_SHARED',
  SET_CHAT_MEMBERS: 'SET_CHAT_MEMBERS',
  INIT_CHAT_MEMBER: 'INIT_CHAT_MEMBER',
  DELETE_CHAT_MEMBER: 'DELETE_CHAT_MEMBER',
})

export const NOTIFICATIONS = new TYPE_S({
  MODULE: 'Notifications/',
  INIT_NOTIFICATIONS: 'INIT_NOTIFICATIONS',
})

export const INTEGRATIONS = new TYPE_S({
  MODULE: 'Integrations/',
  INIT_INTEGRATIONS: 'INIT_INTEGRATIONS',
  INIT_INTEGRATION_DETAILS: 'INIT_INTEGRATION_DETAILS',
  UPDATE_INTEGRATION_DETAIL: 'UPDATE_INTEGRATION_DETAIL',
  UPDATE_INTEGRATION_STATUS: 'UPDATE_INTEGRATION_STATUS',
  SET_LOADING_INTEGRATION: 'SET_LOADING_INTEGRATION',
})

export const INVITEMEMBERS = new TYPE_S({
  MODULE: 'InviteMembers/',
  INIT_INVITES: 'INIT_INVITES',
  DELETE_INVITE: 'DELETE_INVITE',
})

export const FILES = new TYPE_S({
  MODULE: 'Files/',
  INIT_FILES: 'INIT_FILES',
  UPDATE_PERCENT_DOWNLOAD: 'UPDATE_PERCENT_DOWNLOAD',
  DELETE_FILES: 'DELETE_FILES',
})

export const FOLDERS = new TYPE_S({
  MODULE: 'Folders/',
  INIT_FOLDERS: 'INIT_FOLDERS',
  DELETE_FOLDERS: 'DELETE_FOLDERS',
  UPDATE_PERCENT_DOWNLOAD: 'UPDATE_PERCENT_DOWNLOAD',

  CHANGE_COUNT_FILES: 'CHANGE_COUNT_FILES',
  CHANGE_COUNT_FOLDERS: 'CHANGE_COUNT_FOLDERS',
})

export const USERS = new TYPE_S({
  MODULE: 'User/',
  INIT_USER: 'INIT_USER',
  INIT_USERS: 'INIT_USERS',
  UPDATE_AUTH: 'UPDATE_AUTH',
})

export const LABELS = new TYPE_S({
  MODULE: 'Labels/',
  INIT_LABEL: 'INIT_LABEL',
  INIT_LABELS: 'INIT_LABELS',
  INIT_DEFAULT_COLORS: 'INIT_DEFAULT_COLORS',
  DELETE_LABEL: 'DELETE_LABEL',
})

export const REGISTRATIONS = new TYPE_S({
  MODULE: 'Registrations/',
  INIT_REGISTRATIONS: 'INIT_REGISTRATIONS',
  DELETE_REGISTRATION: 'DELETE_REGISTRATION',
})

export const COMMENTS = new TYPE_S({
  MODULE: 'Comments/',
  DELETE_COMMENTS: 'DELETE_COMMENTS',
  INIT_COMMENTS: 'INIT_COMMENTS',
})

export const COMPANYPROJECTSTATUS = new TYPE_S({
  MODULE: 'CompanyProjectStatus/',
  INIT_STATUSES: 'INIT_STATUSES',
  UPDATE_POSITION: 'UPDATE_POSITION',
})

export const COMPANY_PROJECT_TEMPLATES = new TYPE_S({
  MODULE: 'CompanyProjectTemplates/',
  INIT_PROJECT_TEMPLATES: 'INIT_PROJECT_TEMPLATES',
  SET_PROJECT_TEMPLATES: 'SET_PROJECT_TEMPLATES',
  DELETE_PROJECT_TEMPLATES: 'DELETE_PROJECT_TEMPLATES',
  SET_LOADING: 'SET_LOADING',
  UPDATE_TEMPLATE_PROJECT_NUMBER: 'UPDATE_TEMPLATE_PROJECT_NUMBER',
})

export const CHECKLISTS = new TYPE_S({
  MODULE: 'Checklists/',
  DELETE_CHECKLIST: 'DELETE_CHECKLIST',
  UPDATE_CHECKLIST: 'UPDATE_CHECKLIST',
  UPDATE_POSITION_CHECKLIST: 'UPDATE_POSITION_CHECKLIST',
  UPDATE_POSITION_CHECKLIST_ITEM: 'UPDATE_POSITION_CHECKLIST_ITEM',
  INIT_CHECKLIST_ITEMS: 'INIT_CHECKLIST_ITEMS',
  HIDE_COMPLETED_CHECKLIST_ITEMS: 'HIDE_COMPLETED_CHECKLIST_ITEMS',
  SHOW_COMPLETED_CHECKLIST_ITEMS: 'SHOW_COMPLETED_CHECKLIST_ITEMS',
  DELETE_CHECKLIST_ITEM: 'DELETE_CHECKLIST_ITEM',
  UPDATE_CHECKLIST_ITEM: 'UPDATE_CHECKLIST_ITEM',
  UPDATE_CHECKLIST_ITEM_FILE_AND_COMMENT_COUNTS: 'UPDATE_CHECKLIST_ITEM_FILE_AND_COMMENT_COUNTS',
  INIT_CHECKLIST_ITEM_MEMBER: 'INIT_CHECKLIST_ITEM_MEMBER',
  SET_CHECKLIST_ITEM_MEMBER: 'SET_CHECKLIST_ITEM_MEMBER',
  DELETE_CHECKLIST_ITEM_MEMBER: 'DELETE_CHECKLIST_ITEM_MEMBER',
  INIT_HIDDEN_CHECKLIST_ITEMS: 'INIT_HIDDEN_CHECKLIST_ITEMS',
  INIT_CHECKLIST: 'INIT_CHECKLIST',
  INIT_CHECKLISTS: 'INIT_CHECKLISTS',
  UPDATE_CHECKLIST_ACCESS: 'UPDATE_CHECKLIST_ACCESS',
  UPDATE_CHECKLIST_FAVOURITE: 'UPDATE_CHECKLIST_FAVOURITE',
  INIT_CHECKLIST_ITEM_LOGS: 'INIT_CHECKLIST_ITEM_LOGS',
  INIT_TASKS: 'INIT_TASKS',
  CLEAR_TASKS: 'CLEAR_TASKS',
  SET_TASKS: 'SET_TASKS',
  DELETE_TASK: 'DELETE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  INIT_TASK_MEMBER: 'INIT_TASK_MEMBER',
  SET_TASK_MEMBER: 'SET_TASK_MEMBER',
  DELETE_TASK_MEMBER: 'DELETE_TASK_MEMBER',
  UPDATE_CHECKLIST_EXPANDED_STATE: 'UPDATE_CHECKLIST_EXPANDED_STATE',
  SET_UPDATING_CHECKLIST_ITEM: 'SET_UPDATING_CHECKLIST_ITEM',
  SET_CHECKLIST_ITEM_LABELS: 'SET_CHECKLIST_ITEM_LABELS',
  SET_TASK_LABELS: 'SET_TASK_LABELS',

  INIT_CHECKLIST_ITEM_RESOURCE: 'INIT_CHECKLIST_ITEM_RESOURCE',
  SET_CHECKLIST_ITEM_RESOURCES: 'SET_CHECKLIST_ITEM_RESOURCES',
  INIT_TASK_RESOURCE: 'INIT_TASK_RESOURCE',
  SET_TASK_RESOURCES: 'SET_TASK_RESOURCES',
});

export const CHECKLIST_OPTIONS = new TYPE_S({
  MODULE: 'ChecklistOptionsPanel/',
  SET_MODAL_FLAG: 'SET_MODAL_FLAG',
  SHOW_CHECKLIST_OPTIONS: 'SHOW_CHECKLIST_OPTIONS',
  UNCHECK_ALL: 'UNCHECK_ALL',
  TOGGLE_PANEL: 'TOGGLE_PANEL',
  TOGGLE_ALL_ITEMS: 'TOGGLE_ALL_ITEMS',
  UPDATE_ITEM: 'UPDATE_ITEM'
})

export const TASK_OPTIONS = new TYPE_S({
  MODULE: 'TaskOptionsPanel/',
  SET_MODAL_FLAG: 'SET_MODAL_FLAG',
  SHOW_TASK_OPTIONS: 'SHOW_TASK_OPTIONS',
  UNCHECK_ALL: 'UNCHECK_ALL',
  TOGGLE_PANEL: 'TOGGLE_PANEL',
  TOGGLE_ALL_ITEMS: 'TOGGLE_ALL_ITEMS',
  UPDATE_ITEM: 'UPDATE_ITEM'
})

export const COMPANIES = new TYPE_S({
  MODULE: 'Companies/',
  INIT_COMPANY: 'INIT_COMPANY',
  INIT_COMPANY_MEMBERS: 'INIT_COMPANY_MEMBERS',
  DELETE_COMPANY_MEMBER: 'DELETE_COMPANY_MEMBERS',
})

export const PROJECTS_IMPORTS = new TYPE_S({
  MODULE: 'ProjectsImports/',
  INIT_IMPORTS: 'INIT_IMPORTS',
  ADD_IMPORT: 'ADD_IMPORT',
  UPDATE_IMPORT: 'UPDATE_IMPORT',
  REMOVE_IMPORT: 'REMOVE_IMPORT',
  REMOVE_IMPORT_BY_PROJECT_ID: 'REMOVE_IMPORT_BY_PROJECT_ID',
  INIT_FILES: 'INIT_FILES',
  ADD_FILE: 'ADD_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_FILE_STATUS: 'SET_FILE_STATUS'
})

export const IMPORTS = new TYPE_S({
  MODULE: 'ImportFiles/',
  INIT_IMPORTS: 'INIT_IMPORTS',
  ADD_IMPORT: 'ADD_IMPORT',
  UPDATE_IMPORT: 'UPDATE_IMPORT',
  REMOVE_IMPORT_DATA: 'REMOVE_IMPORT_DATA',
  INIT_FILES: 'INIT_FILES',
  ADD_FILE: 'ADD_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_FILE_STATUS: 'SET_FILE_STATUS',
  REMOVE_ALL_IMPORT_DATA: 'REMOVE_ALL_IMPORT_DATA',
  REMOVE_IMPORT_BY_FILE_ID: 'REMOVE_IMPORT_BY_FILE_ID',
  SET_TIMER: 'SET_TIMER',
  RESET_TIMER: 'RESET_TIMER'
})

export const MATERIALS_IMPORTS = new TYPE_S({
  MODULE: 'MaterialsImports/',
  INIT_IMPORTS: 'INIT_IMPORTS',
  ADD_IMPORT: 'ADD_IMPORT',
  UPDATE_IMPORT: 'UPDATE_IMPORT',
  REMOVE_IMPORT: 'REMOVE_IMPORT',
  REMOVE_IMPORT_BY_REGISTRATION_TYPE_ID: 'REMOVE_IMPORT_BY_REGISTRATION_TYPE_ID',
  INIT_FILES: 'INIT_FILES',
  ADD_FILE: 'ADD_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_FILE_STATUS: 'SET_FILE_STATUS'
})

export const FORM_EDITOR = new TYPE_S({
  MODULE: 'FormEditor/',
  SET_INITIAL_FORM: 'SET_INITIAL_FORM',
  SET_HAS_CHANGES: 'SET_HAS_CHANGES',
  SET_FORM: 'SET_FORM',
  SET_FORM_NAME: 'SET_FORM_NAME',
  SET_FORM_DESCRIPTION: 'SET_FORM_DESCRIPTION',
  ADD_FIELD: 'ADD_FIELD',
  REMOVE_FIELD: 'REMOVE_FIELD',
  COPY_FIELD: 'COPY_FIELD',
  SET_FIELD_NAME: 'SET_FIELD_NAME',
  SET_FIELD_TYPE: 'SET_FIELD_TYPE',
  SET_FIELD_REQUIRED: 'SET_FIELD_REQUIRED',
  ADD_FIELD_OPTION: 'ADD_FIELD_OPTION',
  REMOVE_FIELD_OPTION: 'REMOVE_FIELD_OPTION',
  SET_FIELD_OPTION_NAME: 'SET_FIELD_OPTION_NAME',
  SET_FIELD_ATTACHMENTS: 'SET_FIELD_ATTACHMENTS',
  SET_FIELD_POSITIONS: 'SET_FIELD_POSITIONS',
});

export const FORMS = new TYPE_S({
  MODULE: 'Forms/',
  SET_LOADING: 'SET_LOADING',
  INIT_FORMS: 'INIT_FORMS',
  REMOVE_FORM: 'REMOVE_FORM',
  SET_LAST_CREATED_FORM: 'SET_LAST_CREATED_FORM',
  INIT_FORM_LOGS: 'INIT_FORM_LOGS',

  SET_RESPONSE_LOADING: 'SET_RESPONSE_LOADING',
  INIT_FORM_FIELD_RESPONSES: 'INIT_FORM_FIELD_RESPONSES',
  SET_LAST_CREATED_RESPONSE: 'SET_LAST_CREATED_RESPONSE',
});

export const REFERRALS = new TYPE_S({
  MODULE: 'Referrals/',
  SET_REFERRALS_LIST: 'SET_REFERRALS_LIST',
  SET_REFERRAL: 'SET_REFERRAL',
});

export const PRF_IMPORTS = new TYPE_S({
  MODULE: 'PdfImports/',
  SET_LOADING: 'SET_LOADING',
  INIT_TEMPLATES: 'INIT_TEMPLATES',
});
